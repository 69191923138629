import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LinkTo from "../components/LinkTo"
import AppWrapper from "../views/AppWrapper"
import Hero from "../views/Hero"
import CircleIcon from "../elements/CircleIcon"
import { H1Light, PLight, Highlight, WrapTextBlock } from "../elements/Text"

const CloseWrapper = styled.div`
  ${tw`md:absolute`};
  right: 2%;
  top: 2%;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <AppWrapper>
      <Hero isDark>
        <CloseWrapper style={{ opacity: 0 }}>
          <LinkTo href="/">
            <CircleIcon />
          </LinkTo>
        </CloseWrapper>

        <H1Light style={{ opacity: 0 }}>
          Not
          <Highlight> Found</Highlight>
        </H1Light>
        <PLight style={{ opacity: 0 }}>
          <WrapTextBlock>You just hit a route</WrapTextBlock>
          that doesn&#39;t exist... the sadness. But there is hope.
        </PLight>
      </Hero>
    </AppWrapper>
  </Layout>
)

export default NotFoundPage
